import { computed, reactive } from 'vue';
import { ColorField } from '@zoomcatalog/design-templates';
import { useQuery, useQueryCache } from '@zoomcatalog/query-client';
import { AxiosError } from 'axios';
import api from '@/config/axios';
import { GET_COMPANY_SETTINGS_BY_KEY } from '@/config/endpoints';
import { useSelector } from '@/store/rootStore';

export type SettingSectionKey =
  | 'brand'
  | 'currencies'
  | 'product-library'
  | 'product-filters'
  | 'smart-layouts';

export type CompanySettingSection =
  | Restrictions
  | Brand
  | Currency
  | SmartLayout
  | ProductLibrary
  | ProductFilters;

export type Brand = {
  brand: {
    company_id: string;
    logo_url: string;
    square_logo_url: string;
    primary_color: string;
  };
};

export type Restrictions = {
  restrictions: Record<string, 0 | 1>;
};

export type Currency = {
  currencies: Array<{
    currency_id: string;
    company_id: string;
  }>;
};

export type ProductLibrary = {
  'product-library': {
    color_field?: ColorField;
    mark_up?: string;
    price_type?: string;
    config_type?: string;
    custom_mark_up?: string;
    include_brand?: '1' | '0';
    has_variations?: '1' | '0';
    price_label?: 'none' | 'as_low_as' | 'starting_at' | 'msrp';
    product_id_field: 'part_id' | 'style_id' | 'style_name';
  };
};

export type ProductFilterKey =
  | 'Pricing'
  | 'Department'
  | 'Category'
  | 'License'
  | 'Trend'
  | 'Season';
export type ProductFilters = {
  'product-filters': {
    [filterKey in ProductFilterKey]: {
      visible: '1' | '0';
      attributes: string;
    };
  };
};

export interface SmartLayout {
  'smart-layouts': {
    is_enabled: '1' | '0';
    is_available: '1' | '0';
  };
}

type CompanySettingSectionQueryContext = {
  options: {
    section: SettingSectionKey;
    companyId: string | undefined;
  };
  handlers?: {
    onSuccess?(data: CompanySettingSection): void;
  };
};
export function useCompanySettingSectionQuery(
  context?: CompanySettingSectionQueryContext
) {
  const endpoints = useSelector((state) => state.sniffer.endpoints);

  return useQuery<CompanySettingSection, AxiosError>(
    'company-setting-section',
    reactive({
      queryFn: async () =>
        api
          .get<CompanySettingSection>(
            GET_COMPANY_SETTINGS_BY_KEY.endpoint('product-library'),
            {
              headers: {
                'X-Zoom-Company': context?.options.companyId ?? '',
              },
              baseURL: endpoints.value.COMPANIES_API_ENDPOINT,
            }
          )
          .then((data) => data.data),
      onSuccess(data) {
        context?.handlers?.onSuccess?.(data);
      },
      enabled: computed(() => Boolean(context?.options.companyId)),
      staleTime: Infinity,
      refetchOnMount: false,
    })
  );
}

export function useInvalidateCompanySettingSectionQuery() {
  const cache = useQueryCache();
  return async () => cache.invalidateQueries('company-setting-section');
}

export function isProductLibrarySetting(
  companySettingSection: unknown
): companySettingSection is ProductLibrary {
  return (
    typeof companySettingSection === 'object' &&
    companySettingSection !== null &&
    'product-library' in companySettingSection
  );
}
export function isRestrictionsSetting(
  companySettingSection: unknown
): companySettingSection is Restrictions {
  return (
    typeof companySettingSection === 'object' &&
    companySettingSection !== null &&
    'restrictions' in companySettingSection
  );
}
